<template>
  <v-app>
    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>OGZ App</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer v-if="isSignIn" v-model="drawer" app clipped>
      <!-- mini-variant -->
      <!-- temporary -->
      <!-- :permanent="$vuetify.breakpoint.lgAndUp" -->
      <v-list nav>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Aktienliste</v-list-item-title>
          </v-list-item>
          <v-list-item to="/upload">
            <v-list-item-icon>
              <v-icon>mdi-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Upload</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Angemeldet als</v-list-item-subtitle>
          <v-list-item-title>
            {{ user && user.attributes.email }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <amplify-sign-out v-show="isSignIn"></amplify-sign-out> -->
      <amplify-sign-out></amplify-sign-out>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters /* , mapActions*/ } from "vuex";

export default {
  name: "App",
  components: {},
  created() {},
  data() {
    return {
      drawer: true,
      group: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isSignIn"]),
  },
  beforeDestroy() {},
};
</script>
<style>
@import "~vue-awesome-notifications/dist/styles/style.css";
#awn-toast-container {
  font-family: Roboto, sans-serif;
}
</style>
