import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "../views/PageNotFound.vue";
import MainView from "../views/MainView.vue";
import LoginView from "../views/LoginView.vue";
import UploadView from "../views/UploadView.vue";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import store from "../store";

Vue.use(VueRouter);

/// login handling (could maybe moved somewhere else)
let user;

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit("setUser", data);
        // Get identityId as well as long as Storage.put only returns filename
        Auth.currentUserCredentials().then((creds) => {
          store.commit("setIdentityId", creds.identityId);
        });
        return data;
      }
    })
    .catch(() => {
      store.commit("setUser", null);
      return null;
    });
}

if (!store.getters["isSignIn"]) user = getUser();

Hub.listen("auth", async (data) => {
  if (data.payload.event === "signOut") {
    user = null;
    store.commit("setUser", null);
    store.commit("setIdentityId", null);
    router.push({ path: "/login" });
  } else if (data.payload.event === "signIn") {
    user = await getUser();
    router.push({ path: "/" });
  }
});

/// routes
const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/upload",
    name: "UploadView",
    component: UploadView,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

/// the router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/// Navigation Guards
router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      return next({
        path: "/login",
      });
    }
    return next();
  }
  return next();
});

export default router;
