/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addGlobalStockVersion = /* GraphQL */ `
  mutation AddGlobalStockVersion($input: AddStockVersionInput!) {
    addGlobalStockVersion(input: $input) {
      result
    }
  }
`;

export const addLocalStockVersion = /* GraphQL */ `
  mutation AddLocalStockVersion($input: AddStockVersionInput!) {
    addLocalStockVersion(input: $input) {
      result
    }
  }
`;

export const updateGlobalStockVersion = /* GraphQL */ `
  mutation UpdateGlobalStockVersion($input: UpdateStockVersionInput!) {
    updateGlobalStockVersion(input: $input) {
      result
    }
  }
`;

export const updateLocalStockVersion = /* GraphQL */ `
  mutation UpdateLocalStockVersion($input: UpdateStockVersionInput!) {
    updateLocalStockVersion(input: $input) {
      result
    }
  }
`;

export const deleteGlobalStockVersion = /* GraphQL */ `
  mutation DeleteGlobalStockVersion($symbol: ID!) {
    deleteGlobalStockVersion(symbol: $symbol) {
      result
    }
  }
`;

export const deleteLocalStockVersion = /* GraphQL */ `
  mutation DeleteLocalStockVersion($symbol: ID!) {
    deleteLocalStockVersion(symbol: $symbol) {
      result
    }
  }
`;
