<template>
  <v-container id="loginview" fluid tag="section">
    <amplify-auth-container>
      <amplify-authenticator username-alias="email">
        <amplify-sign-up
          slot="sign-up"
          username-alias="email"
          :formFields.prop="formFields"
        ></amplify-sign-up>
        <amplify-sign-in
          slot="sign-in"
          username-alias="email"
        ></amplify-sign-in>
      </amplify-authenticator>
    </amplify-auth-container>
  </v-container>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      formFields: [{ type: "email" }, { type: "password" }],
    };
  },
};
</script>
