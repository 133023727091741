<template>
  <v-container id="uploadview" fluid tag="section">
    <v-row>
      <v-col cols="4">
        <h1>Upload</h1>
        <v-form ref="form" v-model="formValid" lazy-validation>
          <v-select
            label="Sichtbarkeit"
            prepend-icon="mdi-shield-lock-outline"
            :items="stockAccessLeveL"
            v-model="selectedStockAccessLeveL"
            item-value="value"
            item-text="text"
          ></v-select>
          <v-file-input
            accept="application/pdf"
            label="VL-Bericht auswählen *"
            v-model="chosenFile"
            :rules="fileRules"
          >
            <!-- <template v-slot:append>
              <v-btn
                @click="uploadFile"
                color="success"
                small
                :disabled="!chosenFile"
              >
                <v-icon left> mdi-cloud-upload </v-icon> upload
              </v-btn>
            </template> -->
          </v-file-input>
          <v-text-field
            v-model="industry_rank"
            label="Industry Rank *"
            required
            prepend-icon="mdi-medal-outline"
            :rules="irRules"
            type="number"
            min="1"
            max="99"
            step="1"
          ></v-text-field>

          <v-slider
            class="mt-6"
            label="Management Bewertung"
            prepend-icon="mdi-medal"
            v-model="management_rank"
            min="1"
            max="6"
            ticks="always"
            tick-size="4"
            thumb-label="always"
            thumb-size="22"
          ></v-slider>

          <v-text-field
            v-model="recent_price"
            label="Aktueller Preis"
            prepend-icon="mdi-cash"
            :rules="priceRules"
            type="number"
            min="0"
            step=".01"
          ></v-text-field>

          <!-- <v-btn
            :disabled="!formValid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Validate
          </v-btn>

          
          <v-btn color="warning" @click="resetValidation">
            Reset Validation
          </v-btn> -->
          <v-row>
            <v-col class="text-right">
              <v-btn
                color="error"
                class="mr-2"
                @click="reset"
                :disabled="processingState != null"
              >
                Reset
              </v-btn>
              <v-btn
                @click="uploadFile"
                color="success"
                :disabled="!formValid || processingState != null"
              >
                <v-icon left> mdi-cloud-upload </v-icon> upload
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <template v-if="processingState != null">
              <v-progress-linear indeterminate></v-progress-linear>
            </template>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { API, Storage } from "aws-amplify";
import {
  addGlobalStockVersion,
  addLocalStockVersion,
} from "../graphql/mutations";
import { mapState } from "vuex";
const STOCK_ACCESS_LEVEL_PRIVATE = "private";
const STOCK_ACCESS_LEVEL_PUBLIC = "public";
const DEFAULT_MANAGEMENT_RANK = 3;

export default {
  name: "UploadView",
  components: {},
  data() {
    return {
      chosenFile: null,
      formValid: true,
      stockAccessLeveL: [
        { value: STOCK_ACCESS_LEVEL_PRIVATE, text: "Persönlich" },
        { value: STOCK_ACCESS_LEVEL_PUBLIC, text: "OGZ-Gruppe" },
      ],
      selectedStockAccessLeveL: STOCK_ACCESS_LEVEL_PRIVATE,
      industry_rank: "",
      management_rank: DEFAULT_MANAGEMENT_RANK,
      recent_price: null,
      irRules: [
        (v) => !!v || "Industry Rank ist erforderlich.",
        (v) => v >= 1 || "muss größer oder gleich 1 sein.",
        (v) => v < 100 || "muss kleiner gleich 99 sein.",
        (v) => Number.isInteger(parseFloat(v)) || "Muss eine ganze Zahl sein",
      ],
      fileRules: [(v) => !!v || "VL-Bericht ist erforderlich."],
      priceRules: [
        (v) => v == null || v == "" || v > 0 || "Preis muss Positiv sein.",
      ],
      processingState: null,
    };
  },
  computed: {
    ...mapState(["identityId"]),
  },
  methods: {
    // validate() {
    //   const valid = this.$refs.form.validate();
    //   console.log(valid);
    // },
    // resetValidation() {
    //   this.$refs.form.resetValidation();
    // },
    reset() {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.selectedStockAccessLeveL = STOCK_ACCESS_LEVEL_PRIVATE;
        this.management_rank = DEFAULT_MANAGEMENT_RANK;
      });
    },
    async uploadFile() {
      if (this.$refs.form.validate()) {
        // TODO: Replace with Enum
        this.processingState = "Upload & Analyse";
        const filePath = "upload/valueline_reports/" + this.chosenFile.name;
        try {
          const result = await Storage.put(filePath, this.chosenFile, {
            level: "private",
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
          });

          const s3FullPath = "private/" + this.identityId + "/" + result.key;

          // Trigger VL analysis
          try {
            await API.graphql({
              query:
                this.selectedStockAccessLeveL == STOCK_ACCESS_LEVEL_PRIVATE
                  ? addLocalStockVersion
                  : addGlobalStockVersion,
              variables: {
                input: {
                  industry_rank: this.industry_rank,
                  management_rank: this.management_rank,
                  recent_price: this.recent_price,
                  valueline_report_key: s3FullPath,
                },
              },
            });
            this.$awn.success("Upload & Analyse erfolgreich.");
            this.$router.push({ name: "MainView" });
          } catch (err) {
            this.$awn.warning("Analyse ist fehlgeschlagen.");
            console.log(err);
          }
          this.reset();
        } catch (err) {
          this.$awn.warning("Upload ist fehlgeschlagen.");
          console.log(err);
        }
      } else {
        this.$awn.warning("Bitte Formular überprüfen.");
      }
      this.processingState = null;
    },
  },
};
</script>
