import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueAWN from "vue-awesome-notifications";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];
Vue.config.productionTip = false;

const options = {
  durations: {
    global: 10000,
    success: 4000,
  },
  icons: {
    enabled: true,
    prefix: "<i class='v-icon notranslate mdi theme--light mdi-",
    suffix: "'></i>",
    tip: "comment-question",
    info: "comment-text",
    success: "comment-check",
    warning: "comment-alert",
    alert: "alert",
    async: "cog",
    confirm: "progress-check",
  },
  labels: {
    tip: "Tipp",
    info: "Info",
    success: "Erfolg",
    warning: "Warnung",
    alert: "Achtung",
    async: "Lade...",
    confirm: "Bestätigung erforderlich",
    confirmOk: "OK",
    confirmCancel: "Abbruch",
  },
};
Vue.use(VueAWN, options);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
