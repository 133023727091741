<template>
  <section>
    <v-row align="center" no-gutters>
      <v-col>
        <span class="text-h6">Filter</span>
      </v-col>
    </v-row>
    <v-row align="center" no-gutters>
      <v-col cols="2">
        <span class="text-subtitle-2"> Symbol </span>
      </v-col>
      <v-col cols="2">
        <v-text-field
          dense
          single-line
          clearable
          v-model="filterSymbol"
          label="Symbol - Filter"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <ranking-pills
      v-model="filterQuality"
      caption="PJM Eigenschaften"
    ></ranking-pills>
    <ranking-pills
      v-model="filterQuantity"
      caption="PJM Bewertung"
    ></ranking-pills>
    <v-divider class="my-6"></v-divider>
    <v-row align="center" no-gutters>
      <v-col>
        <span class="text-h6">Aktienliste</span>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredStocks"
      :loading="dataLoading"
      item-key="id"
      dense
      calculate-widths
      show-expand
      single-expand
      multi-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:footer>
        <section id="footer" class="mb-4">
          <v-divider></v-divider>
        </section>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.symbol="{ item }">
        <div :class="getSymbolStyle(item)">{{ item.symbol }}</div>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.pjm_data.quality.e_rank="{ item }">
        <v-chip
          small
          align="center"
          class="chip-fw"
          :color="getColorRank(item.pjm_data.quality.e_rank)"
        >
          {{ item.pjm_data.quality.e_rank }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.pjm_data.quantity.b_rank="{ item }">
        <v-chip
          small
          align="center"
          class="chip-fw"
          :color="getColorRank(item.pjm_data.quantity.b_rank)"
        >
          {{ item.pjm_data.quantity.b_rank }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card tile outlined>
            <v-card-title class="text-subtitle-1"
              >Detailansicht {{ item.symbol }}
            </v-card-title>
            <v-card-text>
              <template v-if="!editMode">
                <v-row no-gutters class="mb-2">
                  <v-col class="text-caption" cols="2"> Unternehmen </v-col>
                  <v-col>
                    {{ item.company }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="mb-2">
                  <v-col class="text-caption" cols="2"> Sektor </v-col>
                  <v-col>
                    {{ item.sector }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="mb-2">
                  <v-col class="text-caption" cols="2"> Information </v-col>
                  <v-col>
                    {{ item.information }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="mb-2">
                  <v-col class="text-caption" cols="2"> VL-Report </v-col>
                  <v-col>
                    <a
                      v-bind:href="item.downloadUrl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-text-field
                  v-model="company"
                  label="Unternehmen"
                  prepend-icon="mdi-domain"
                  type="string"
                ></v-text-field>
                <v-text-field
                  v-model="sector"
                  label="Sektor"
                  prepend-icon="mdi-tag"
                  type="string"
                ></v-text-field>
                <v-text-field
                  v-model="information"
                  label="Information"
                  prepend-icon="mdi-information"
                  type="string"
                ></v-text-field>
              </template>

              <v-card-actions>
                <template v-if="!editMode">
                  <v-btn color="error" @click="deleteStock(item)">
                    <v-icon center> mdi-delete-outline </v-icon>Löschen
                  </v-btn>
                  <v-btn color="primary" @click="activateEditMode(item)">
                    <v-icon center> mdi-pencil </v-icon>Bearbeiten
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn color="secondary" @click="resetEditMode()">
                    <v-icon center> mdi-pencil </v-icon>Abbrechen
                  </v-btn>
                  <v-btn color="success" @click="updateStock(item)">
                    <v-icon center> mdi-pencil </v-icon>Speichern
                  </v-btn>
                </template>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { API, Storage } from "aws-amplify";
import {
  deleteGlobalStockVersion,
  deleteLocalStockVersion,
  updateGlobalStockVersion,
  updateLocalStockVersion,
} from "../../graphql/mutations";
import { listLatestStockVersions } from "../../graphql/queries";
import RankingPills from "./RankingPills.vue";

export default {
  name: "StockTable",
  components: { RankingPills },
  async created() {
    this.getStocks();
    // this.subscribe();
  },
  data() {
    return {
      company: "",
      sector: "",
      information: "",
      filterSymbol: "",
      filterQuality: ["A+", "A", "A-", "B+", "B", "B-"],
      filterQuantity: ["A+", "A", "A-", "B+", "B", "B-"],
      dataLoading: true,
      editMode: false,
      stocks: [],
      headers: [
        {
          text: "Symbol",
          value: "symbol",
          // width: 100,
        },
        {
          text: "PJM Eig.",
          value: "pjm_data.quality.e_rank",
          // align: "center",
        },
        {
          text: "PJM Bew.",
          value: "pjm_data.quantity.b_rank",
          // align: "center",
        },
        {
          text: "aktueller Kurs",
          value: "pjm_data.quantity.b6a_recent_price",
        },
        {
          text: "min Kurs",
          value: "pjm_data.quantity.b_lowest_share_price",
        },
        {
          text: "max Kaufen-Kurs",
          value: "pjm_data.quantity.b_highest_buy_price",
        },
        {
          text: "max Halten-Kurs",
          value: "pjm_data.quantity.b_highest_hold_price",
        },
        {
          text: "Ziel-Kurs",
          value: "pjm_data.quantity.b_highest_share_price",
        },
        {
          text: "Action",
          value: "pjm_data.action",
          filterable: false,
        },
      ],
    };
  },
  watch: {},
  computed: {
    filteredStocks() {
      return this.stocks.filter((v) => {
        let has_symbol = true,
          has_quality = false,
          has_quantity = false;

        if (v.symbol && this.filterSymbol) {
          has_symbol = v.symbol
            .toUpperCase()
            .includes(this.filterSymbol.toUpperCase());
        }
        if (this.filterQuality.includes(v.pjm_data.quality.e_rank)) {
          has_quality = true;
        }
        if (this.filterQuantity.includes(v.pjm_data.quantity.b_rank)) {
          has_quantity = true;
        }

        return has_symbol && has_quality && has_quantity;
      });
    },
  },
  methods: {
    // async createStock() {
    //   const { symbol, company, sector, information } = this;
    //   if (!symbol || !company || !sector || !information) return;
    //   const stock = { symbol, company, sector, information };
    //   // TODO: does not wait for success of graphql?
    //   this.stocks = [...this.stocks, stock];
    //   await API.graphql({
    //     query: createStock,
    //     variables: { input: stock },
    //   });
    //   this.symbol = "";
    //   this.company = "";
    //   this.sector = "";
    //   this.information = "";
    // },
    getSymbolStyle(item) {
      if (item.global_stock) return "font-weight-bold";
      else return "";
    },
    getColorRank(rank) {
      const colors = {
        "A+": "green lighten-1",
        A: "green lighten-1",
        "A-": "green lighten-1",
        "B+": "green lighten-3",
        B: "green lighten-3",
        "B-": "green lighten-3",
        "C+": "deep-orange lighten-2",
        C: "deep-orange lighten-2",
        "C-": "deep-orange lighten-2",
        "D+": "deep-orange lighten-1",
        D: "deep-orange lighten-1",
        "D-": "deep-orange lighten-1",
      };
      try {
        return colors[rank];
      } catch {
        return "";
      }
    },
    async getSignedUrl(key) {
      // Split amplify default access level from key (public/protected/private)
      const accessLevel = key.substr(0, key.indexOf("/"));
      let shortenedKey = key.substr(key.indexOf("/") + 1);
      if (accessLevel == "private") {
        // Remove identityId from key in case we have a private download
        shortenedKey = shortenedKey.substr(shortenedKey.indexOf("/") + 1);
      }
      const signedUrl = await Storage.get(shortenedKey, { level: accessLevel });
      return signedUrl;
    },
    async getStocks() {
      this.dataLoading = true;
      const stocks = await API.graphql({
        query: listLatestStockVersions,
      });
      this.stocks = stocks.data.listLatestStockVersions;
      this.dataLoading = false;

      // Add calculated attributes
      for (const stock of this.stocks) {
        // Stock ID combined from symbol and global/personal
        stock.id = stock.global_stock ? "global#" + stock.symbol : stock.symbol;
        // Pre-signed URLs for downloading VL Reports
        stock.downloadUrl = await this.getSignedUrl(
          stock.valueline_report.file_object.key
        );
      }
    },
    async deleteStock(item) {
      try {
        await API.graphql({
          query:
            item.global_stock == true
              ? deleteGlobalStockVersion
              : deleteLocalStockVersion,
          variables: {
            symbol: item.symbol,
          },
        });
        this.$awn.success("Aktie erfolgreich gelöscht.");
        this.getStocks();
      } catch (err) {
        this.$awn.warning("Löschen ist fehlgeschlagen.");
        console.log(err);
      }
    },
    activateEditMode(item) {
      this.company = item.company;
      this.sector = item.sector;
      this.information = item.information;
      this.editMode = true;
    },
    resetEditMode() {
      this.company = "";
      this.sector = "";
      this.information = "";
      this.editMode = false;
    },
    async updateStock(item) {
      try {
        await API.graphql({
          query:
            item.global_stock == true
              ? updateGlobalStockVersion
              : updateLocalStockVersion,
          variables: {
            input: {
              symbol: item.symbol,
              version: item.version,
              company: this.company,
              sector: this.sector,
              information: this.information,
            },
          },
        });
        this.resetEditMode();
        this.$awn.success("Aktie erfolgreich aktualisiert.");
        this.getStocks();
      } catch (err) {
        this.$awn.warning("Aktualisieren ist fehlgeschlagen.");
        console.log(err);
      }
    },
  },
};
</script>
<style scoped>
.chip-fw {
  display: inline-block;
  width: 3rem;
}
</style>
