<template>
  <v-container id="pagenotfound" fluid tag="section">
    <h1>Hmm, diese Seite scheint verschwunden zu sein ...</h1>
    <h3>eine &#x1F50D; Suche hilft leider auch nix &#x1F622;</h3>
    <p>404 - Page not found.</p>
  </v-container>
</template>
<script>
export default {
  name: "PageNotFound"
};
</script>