<template>
  <v-container id="mainview" fluid tag="section">
    <v-row>
      <v-col cols="12" offset="0">
        <v-card>
          <!-- <v-card-title>Test Stock List</v-card-title> -->
          <v-card-text>
            <stock-table />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import StockTable from "@/components/stocks/StockTable.vue";
import { mapState } from "vuex";
export default {
  name: "MainView",
  components: { StockTable },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
