/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_appsync_graphqlEndpoint:
    "https://jkygb4724fcd5hrjh24223isre.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "eu-central-1:10dddcaa-deb0-45da-81cb-6a9ff9e86fdc",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_hxIC6kz68",
  aws_user_pools_web_client_id: "479r2omcihp92v8rcbeaqu0l9j",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "p-ogz-app-service-storage",
  aws_user_files_s3_bucket_region: "eu-central-1",
};

export default awsmobile;
