/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listLatestStockVersions = /* GraphQL */ `
  query ListLatestStockVersions {
    listLatestStockVersions {
      symbol
      company
      sector
      information
      version
      global_stock
      valueline_report {
        date
        recent_price
        file_object {
          key
        }
      }
      pjm_data {
        quality {
          e10_management_rank
          e_rank
        }
        quantity {
          b_rank
          b6a_recent_price
          b_lowest_share_price
          b_highest_buy_price
          b_highest_hold_price
          b_highest_share_price
        }
        action
      }
    }
  }
`;
