<template>
  <v-row align="center" justify="space-around" no-gutters class="mb-3">
    <v-col cols="2">
      <p class="text-subtitle-2 mb-0">{{ caption }}</p>
      <v-btn x-small class="mr-1" color="primary" @click="selectAll">
        alle
      </v-btn>
      <v-btn x-small class="mr-1" color="primary lighten-2" @click="selectHalf">
        halb
      </v-btn>
      <v-btn x-small class="mr-1" color="" @click="selectNone"> keine </v-btn>
    </v-col>
    <v-col>
      <v-chip-group v-model="sel" active-class="primary--text" column multiple>
        <v-chip
          v-for="rank in ranks"
          :key="rank"
          small
          align="center"
          class="chip-fw"
        >
          {{ rank }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RankingPills",
  props: {
    value: Array,
    caption: String,
  },
  data: () => ({
    ranks: ["A+", "A", "A-", "B+", "B", "B-", "C+", "C", "C-", "D+", "D", "D-"],
    sel: [],
  }),
  computed: {
    selectedRanks: {
      get() {
        const r = [];
        for (const idx of this.sel) {
          r.push(this.ranks[idx]);
        }
        return r;
      },
      set(val) {
        const r = [];
        for (const rank of val) {
          r.push(this.ranks.indexOf(rank));
        }
        r.sort();
        this.sel = r;
      },
    },
  },
  watch: {
    sel() {
      this.$emit("input", this.selectedRanks);
    },
  },
  methods: {
    selectNone() {
      this.sel = [];
    },
    selectHalf() {
      this.sel = [...Array(this.ranks.length / 2).keys()];
    },
    selectAll() {
      this.sel = [...Array(this.ranks.length).keys()];
    },
  },
  mounted() {
    this.selectedRanks = this.value;
  },
};
</script>

<style scoped>
.chip-fw {
  display: inline-block;
  width: 3rem;
}
</style>
